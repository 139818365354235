import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from '@/store'
import { VueAxios } from './axios'
import { Message, Loading } from 'element-ui'
import Routers from '@/router'
// 创建 axios 实例
const service = axios.create({
	baseURL: '/jeecg-boot', // api base_url
	timeout: 60000 // 请求超时时间
})

const err = (error) => {
	if (error.response) {
		let data = error.response.data
		switch (error.response.status) {
			case 403:
				// Message.error('拒绝访问');
				break
			case 500:
				const token = localStorage.getItem('token')
				if (data.message == 'Token失效，请重新登录') {
                    Message.error('长时间未操作，请重新登录');
					localStorage.clear()
					Routers.push('/')
				}else{
					// Message.error('网络错误，请检查网络连接是否正常');
				}
				break
			case 404:
				// Message.error('很抱歉，资源未找到!');
				break
			case 504:
				// Message.error('网络超时');
				break
			case 401:
				// Message.error('未授权，请重新登录');
				break
			default:
				// Message.error('网络错误，请检查网络连接是否正常');
				break
		}
	}
	return Promise.reject(error)
}

// request interceptor

// let loadingInstance
// let count = 0;
service.interceptors.request.use(config => {
	const token = localStorage.getItem('token')
	if (token) {
		config.headers['X-Access-Token'] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
	}
	if (config.method == 'get') {
		config.params = {
			_t: Date.parse(new Date()) / 1000,
			column: 'createTime',
            order: 'desc',
            schHost: location.host,
			...config.params
		}
	}
	return config
}, (error) => {
	return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
	// loadingInstance.close()
    // count = 0
	return response.data
}, err)

const installer = {
	vm: {},
	install(Vue, router = {}) {
		Vue.use(VueAxios, router, service)
	}
}

export {
	installer as VueAxios,
	service as axios
}
