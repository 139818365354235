import Vue from 'vue'
import { axios } from '@/utils/request'

// 用户登陆
export function login(data) {
    return axios({
        url: '/sys/login',
        method: 'post',
        data: data
    })
}
// 退出登录
export function logout(logoutToken) {
    return axios({
        url: '/sys/logout',
        method: 'post',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Access-Token': logoutToken
        }
    })
}
// 用户信息修改
export function setUser(data) {
    return axios({
        url: '/sys/user/edit',
        method: 'put',
        data: data
    })
}
//增加用户
export function addUser(data) {
    return axios({
        url: '/sys/user/register',
        method: 'post',
        data: data,
    })
}
// 修改用户密码
export function editPassword(data) {
    return axios({
        url: '/sys/user/updatePassword',
        method: 'put',
        data: data
    })
}
// 获取用户信息
export function getUserqueryid(data) {
    return axios({
        url: '/sys/user/queryById',
        method: 'get',
        params: data,
    })
}

const user = {
    // 登陆接口
    login(data) {
        return axios({
            url: '/sys/login',
            method: 'post',
            data: data
        })
    },
    // 退出登录
    logout(logoutToken) {
        return axios({
            url: '/sys/logout',
            method: 'post',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'X-Access-Token': logoutToken
            }
        })
    },
    // 获取验证码
    getCaptcha(data) {
        return axios({
            url: '/sys/sms',
            method: 'post',
            data: data
        })
    },
    // 手机号码验证
    verphone(data) {
        return axios({
            url: '/sys/user/phoneVerification',
            method: 'post',
            data: data
        })
    },
    //增加用户
    addUser(data) {
        return axios({
            url: '/sys/user/register',
            method: 'post',
            data: data,
        })
    },
    //验证用户是否存在
    checkOnlyUser() {

    },
    // 获取用户信息
    getUserqueryid(data) {
        return axios({
            url: '/sys/user/queryById',
            method: 'get',
            params: data,
        })
    },
    // 用户信息修改
    setUser(data) {
        return axios({
            url: '/sys/user/edit',
            method: 'put',
            data: data
        })
    },
    // 修改用户密码
    editPassword(data) {
        return axios({
            url: '/sys/user/updatePassword',
            method: 'put',
            data: data
        })
    },
    // 验证用户是否存在
    checkOnlyUser(data) {
        return axios({
            url: '/sys/user/checkOnlyUser',
            method: 'get',
            params: data,
        })
    },
}

export default user