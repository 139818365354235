export default [
    /* 信息查询 */
    //旅游动态
    {
        path: '/dynamic',
        name: 'Dynamic',
        component: () =>
            import ('@/views/main/dynamic/List.vue')
    },
    //旅游动态详情
    {
        path: '/dyDetail',
        name: 'DyDetail',
        component: () =>
            import ('@/views/main/dynamic/Detail.vue')
    },
    //书籍光碟
    {
        path: '/books',
        name: 'Books',
        component: () =>
            import ('@/views/main/books/List.vue')

    },
    // 书籍光碟详情
    {
        path: '/books/detail',
        name: 'Books',
        component: () =>
            import ('@/views/main/books/Detail.vue')

    },
    //虚拟旅游
    {
        path: '/strategy',
        name: 'Strategy',
        component: () =>
            import ('@/views/main/strategy/List.vue')

    },
    // 气象服务
    {
        path: '/meteor',
        name: 'Meteor',
        component: () =>
            import ('@/views/main/meteor/List.vue')
    },
    //旅游攻略详情页
    {
        path: '/stratdata',
        name: 'Stratdata',
        component: () =>
            import ('@/views/main/strategy/Detail.vue')

    },
    //交通指南
    {
        path: '/traffic',
        name: 'Traffic',
        component: () =>
            import ('@/views/main/Traffic/List.vue')

    },
    //交通指南详情页
    {
        path: '/tradata',
        name: 'Tradata',
        component: () =>
            import ('@/views/main/Traffic/Detail.vue')

    },
    //旅游节庆
    {
        path: '/festival',
        name: 'Festival',
        component: () =>
            import ('@/views/main/festival/List.vue')

    },
    //旅游节庆详情页
    {
        path: '/festidata',
        name: 'Festidata',
        component: () =>
            import ('@/views/main/festival/Detail.vue')

    },
    //门票价格
    {
        path: '/ticketprice',
        name: 'Ticketprice',
        component: () =>
            import ('@/views/main/Ticketprice/List.vue')

    },
    //门票价格详情页
    {
        path: '/ticketdata',
        name: 'Ticketdata',
        component: () =>
            import ('@/views/main/Ticketprice/Detail.vue')

    },
    // 旅游地图
    {
        path: '/travelmap',
        name: 'Travelmap',
        component: () =>
            import ('@/views/main/travelmap/List.vue')
    },
]