export default [
    // 首页
    {
        path: '/',
        name: 'Index',
        component: () =>
            import ('@/views/ORIndex.vue')
    },
    //旅游景点
    {
        path: '/scenicspot',
        name: 'scenicspot',
        component: () =>
            import ('@/views/main/scenicspot/List.vue')

    },
    //旅游景点详情
    {
        path: '/scenicDetail',
        name: 'scenicDetail',
        component: () =>
            import ('@/views/main/scenicspot/Detail.vue')

    },

    //崇信概况
    {
        path: '/survey',
        name: 'Survey',
        component: () =>
            import ('@/views/main/Survey.vue')

    },
    // 旅游文化
    {
        path: '/tourism/list/:type?',
        name: 'Tourism',
        component: () =>
            import ('@/views/main/tourism/List.vue'),
        props: true,
    },
    // 旅游文化详情
    {
        path: '/tourism/detail',
        name: 'Tourism',
        component: () =>
            import ('@/views/main/tourism/Detail.vue'),
    },
    //旅游饭店
    {
        path: '/hotel',
        name: 'Hotel',
        component: () =>
            import ('@/views/main/hotel/List.vue')

    },
    //旅游饭店详情页
    {
        path: '/hotelDetail',
        name: 'HotelDetail',
        component: () =>
            import ('@/views/main/hotel/Detail.vue')

    },
    //援助与投诉
    {
        path: '/complaint',
        name: 'Complaint',
        component: () =>
            import ('@/views/main/Complaint.vue')
    },
    //援助与投诉详情页
    {
        path: '/complaintdata',
        name: 'Complaintdata',
        component: () =>
            import ('@/views/main/Complaintdata.vue')
    },
    //援助与投诉列表页
    {
        path: '/complaintList',
        name: 'ComplaintList',
        component: () =>
            import ('@/views/main/ComplaintList.vue')
    },
    //旅游路线
    {
        path: '/touristrou',
        name: 'Touristrou',
        component: () =>
            import ('@/views/main/touristrou/List.vue')
    },
    //旅游路线详情页
    {
        path: '/tourisdeta',
        name: 'tourisdeta',
        component: () =>
            import ('@/views/main/touristrou/Detail.vue')
    },
    //旅游商业
    {
        path: '/tourismbus',
        name: 'tourismbus',
        component: () =>
            import ('@/views/main/tourismbus/List.vue')
    },
    //旅游商业详情页
    {
        path: '/tourdeta',
        name: 'tourdeta',
        component: () =>
            import ('@/views/main/tourismbus/Detail.vue')
    },
    //特种旅游
    {
        path: '/specialtour',
        name: 'specialtour',
        component: () =>
            import ('@/views/main/specialtour/List.vue')
    },
    //特种旅游详情页
    {
        path: '/speciadate',
        name: 'speciadate',
        component: () =>
            import ('@/views/main/specialtour/Detail.vue')
    },
    //旅游须知
    {
        path: '/touristinfo',
        name: 'touristinfo',
        component: () =>
            import ('@/views/main/touristinfo/List.vue')
    },
    //旅游须知详情页
    {
        path: '/touristdata',
        name: 'touristdata',
        component: () =>
            import ('@/views/main/touristinfo/Detail.vue')
    },
    //最新游记
    {
        path: '/latesttravel',
        name: 'latesttravel',
        component: () =>
            import ('@/views/main/Latesttravel/List.vue')
    },
    //最新游记详情页
    {
        path: '/latestdata',
        name: 'latestdata',
        component: () =>
            import ('@/views/main/Latesttravel/Detail.vue')
    },
    //旅游管理机构
    {
        path: '/tourismmana',
        name: 'tourismmana',
        component: () =>
            import ('@/views/main/tourismmana/List.vue')
    },
    //旅游协会
    {
        path: '/tourismasso',
        name: 'tourismasso',
        component: () =>
            import ('@/views/main/tourismasso/List.vue')
    },
    //旅游协会详情页
    {
        path: '/tourismdata',
        name: 'tourismdata',
        component: () =>
            import ('@/views/main/tourismasso/Detail.vue')
    },
    //娱乐设施
    {
        path: '/entertainment',
        name: 'entertainment',
        component: () =>
            import ('@/views/main/entertainment/List.vue')
    },
    //娱乐设施详情页
    {
        path: '/entertdata',
        name: 'entertdata',
        component: () =>
            import ('@/views/main/entertainment/Detail.vue')
    },
    //旅游购物
    {
        path: '/travelshopping',
        name: 'travelshopping',
        component: () =>
            import ('@/views/main/travelshopping/List.vue')
    },
    //旅游购物详情页
    {
        path: '/travelshopdata',
        name: 'travelshopdata',
        component: () =>
            import ('@/views/main/travelshopping/Detail.vue')
    },
    //旅行社
    {
        path: '/travelagency',
        name: 'travelagency',
        component: () =>
            import ('@/views/main/travelagency/List.vue'),
    },
    //旅行社详情页
    {
        path: '/traveladata',
        name: 'traveladata',
        component: () =>
            import ('@/views/main/travelagency/Detail.vue'),
    },
    //搜索列表页
    {
        path: '/retrieval',
        name: 'Retrieval',
        component: () =>
            import ('@/views/main/Retrieval.vue'),
    }
]