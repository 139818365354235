export default [
    // 个人中心
    {
        path: '/user',
        name: 'user',
        component: () =>
            import ('@/views/user/Personal.vue'),
        redirect: '/user/information',
        children: [
            /* 个人资料 */
            {
                path: 'information',
                name: 'information',
                meta: {
                    isAuthRequired: true
                },
                component: () =>
                    import ('@/views/user/information/index.vue')
            },
            /* 密码修改 */
            {
                path: 'profile',
                name: 'profile',
                meta: {
                    isAuthRequired: true
                },
                component: () =>
                    import ('@/views/user/profile/index.vue')
            },
            /* 我要写游记 */
            {
                path: 'writeadd',
                name: 'writeadd',
                meta: {
                    isAuthRequired: true
                },
                component: () =>
                    import ('@/views/user/writeadd/index.vue')
            },
            /* 我的游记 */
            {
                path: 'mtravels',
                name: 'mtravels',
                meta: {
                    isAuthRequired: true
                },
                component: () =>
                    import ('@/views/user/mtravels/index.vue')
            },
            /* 我的游记详情页 */
            {
                path: 'mtravelsdate',
                name: 'mtravelsdate',
                meta: {
                    isAuthRequired: true
                },
                component: () =>
                    import ('@/views/user/mtravels/Detail.vue')
            },
        ]
    },
]