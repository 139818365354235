import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.min.css'
import {getAction,postAction} from '@/api/manage.js'
import '@/assets/css/common.scss'
import '@/assets/css/reset.css'
import "@/utils/message";

/* 百度地图引入 */
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'QGm1Q6ko1WBFOWNIG42szb5tPjGD7GUm'
})
/************************/

Vue.config.productionTip=false
Vue.use(ElementUI)
Vue.prototype.getAction=getAction;
Vue.prototype.postAction=postAction;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')