<template>
    <div class="tou">
        <el-dialog title="登录" :visible.sync="visibles">
            <el-form
                ref="form"
                label-position="right"
                :model="form"
                :rules="rules"
                label-width="80px"
                @keyup.enter.native="onSubmit('form')"
            >
                <el-form-item label="账号" prop="username">
                    <el-input v-model="form.username" placeholder="请输入账号"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input
                        type="password"
                        v-model="form.password"
                        autocomplete="off"
                        placeholder="请输入密码"
                    ></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit('form')"
                    :loading="loginbtnloading"
                    >立即登陆</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { login } from '@/api/login.js'
export default {
    data() {
        return {
            loginbtnloading: false,
            visibles: false,
            form: {
                username: "",
                password: "",
            },
            rules: {
                username: [
                    {
                        required: true,
                        message: "账号不能为空",
                        trigger: "change",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "密码不能为空",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    methods: {
		handleClose(){
			this.form = {}
		},
        onSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    this.loginbtnloading = true;
					let data = Object.assign(this.form);
					login(data).then(res => {
						if (res.success) {
							this.loginbtnloading = false;
							this.$message.success(res.message);
                            this.visibles=false;
							// 储存token
							localStorage.setItem("token", res.result.token);
							// 储存用户信息
							localStorage.setItem("userInfo",JSON.stringify(res.result.userInfo));
							// 储存用户userid
							localStorage.setItem("userid", res.result.userInfo.id);
							this.$router.push("/user");
						} else {
							this.loginbtnloading = false;
							this.$message.error(res.message);
						}
					})
                } else {
                    return false;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.tou {
    ::v-deep.el-dialog__title {
        font-size: 16px;
        color: #999;
    }
    ::v-deep.el-input__inner {
        width: 312px;
        height: 44px;
        border-radius: 3px;
        border: solid 1px #dbdbdb;
    }
    ::v-deep.el-dialog {
        width: 496px;
    }
    ::v-deep.el-dialog__footer {
        text-align: center;
    }
    .el-button--primary {
        width: 212px;
        height: 44px;
        background-color: #6EB400;
        border-color: #6EB400;
        border-radius: 3px;
    }
    .denlu {
        margin-left: 80px;
        font-size: 12px;
        line-height: 40px;
        color: #6EB400;
    }
    .dialog-footer {
        margin-top: -16px;
        margin-left: -81px;
    }
    .text:hover {
        cursor: pointer;
    }
}
</style>
