<template>

    <div id="app">
        <router-view />
        <Writetravel v-if="write" />
        <Login ref="login" />
    </div>
</template>

<script>
import Login from "@/components/Login";
import Writetravel from "@/components/main/Writetravel";
export default {
    components: {
        Writetravel,
        Login
	},
	mounted() {
		if(this._isMobile()){
            this.write = false
		}
	},
	data() {
		return {
			write: true
		}
	},
	methods: {
        // 判断手机端
        _isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        }
    },
};
</script>

<style lang="scss">
</style>

