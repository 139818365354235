import Vue from 'vue'
import VueRouter from 'vue-router'
import infoserver from './infoserver'   // 信息查询
import main from './main'
import user from './user'
import wap from './wap'

Vue.use(VueRouter)
let routes = new Set([...main, ...user, ...infoserver, ...wap])
routes.add({
    path: '*',
    component: () =>
        import ('@/views/404.vue')
})

const router = new VueRouter({
    routes,
    mode: 'history'
})

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
    document.documentElement.scrollTop = 0
    let token = localStorage.getItem('token')
    if (to.meta.isAuthRequired && !token) {
        return next('/')
    } else {
        next()
    }
});
export default router