<template>
    <div class="write-box">
        <div class="link clear" @click="writebtn">
            <img src="@/assets/image/main/icon/notes-icon.png" class="lf">
            <span class="text lf">我要写游记</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    methods: {
        writebtn(){
            let token = localStorage.getItem('token')
            if(token){
                this.$router.push('/user/writeadd')
            }else{

                console.log()
                this.$parent.$refs.login.visibles = true
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.write-box{
    position: fixed;
    top: 50%;
    right: 48px;
    transform: translateY(-50%);
    z-index: 100;
    background-color: #ff7f3c;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 2px 9px 0px 
		rgba(255, 127, 60, 0.55);
    .link{
        display: block;
        border-radius: 10px;
        border: 1px dashed #fff;
        padding: 12px 14px;
        cursor: pointer;
    }
    .text{
        margin-top: 3px;
        margin-left: 5px;
        color: #fff;
        font-size: 16px;
    }
}
</style>